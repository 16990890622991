.Authentification {
    background-color: var(--main-green-three);
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 95% 5%;
    grid-template-columns: repeat(12, calc(100% / 12));
}
/* Formulaire */
.Authentification > .auth-ctn > form {
    grid-column: 2/12;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.Authentification > .auth-ctn > form > h2 {
    color: var(--main-white);
    width:calc(100% - 15%);
}
.Authentification > .auth-ctn > form > .alert {
    width: calc(100% - 15%);
    margin-bottom: 2vh;
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.Authentification > .auth-ctn > form > .alert > span {
    color: var(--main-alert-red);
}
.Authentification > .auth-ctn {
    background-color: var(--main-green-three);
    grid-column: 2 / 12;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    align-items: center;
    justify-items: center;
    align-content: center;
    row-gap: 15%;
}
.Authentification > .auth-ctn > form > .alert > .text {
    grid-column: 3/12;
}
.Authentification > .auth-ctn > form > .alert > .icon {
    grid-column: 1/3;
}
/* Logo Cirrus */
.Authentification > .auth-ctn > .logo-ctn {
    grid-column: 2 / 12;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;    
    height: 25%;
}
.Authentification > .auth-ctn > .logo-ctn > h1 {
    color: var(--main-white);
    grid-column: 6/12;
}
.Authentification > .auth-ctn > .logo-ctn > img {
    grid-column: 3 / 6;
    width: 100%;
    filter: var(--main-white-filter);
}

/* Footer */
.Authentification > .footer {
    grid-column: 2 / 12;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    bottom: 2vh;
    align-items: flex-end;
}
.Authentification > .footer > .caelus-app-name {
    color: var(--main-white);
    margin-bottom:0;
}
.Authentification > .footer > img {
    filter: var(--main-white-filter);
    width: 2vh;
    margin-right: 1vw;
} 


/* responsive */
@media screen and (min-width: 800px) {
    .Authentification > .auth-ctn {
        background-color: var(--main-white-transparent);
        border-radius: 15px;
        margin:20% 0;
    }
    .Authentification > .auth-ctn > .logo-ctn {
        grid-column: 2 / 5;
    }
    .Authentification > .auth-ctn > form {
        grid-column: 7/12;
    }
    .Authentification > .auth-ctn > form > .Button > img {
        display: none;
    }
    .Authentification > .auth-ctn > .logo-ctn > h1 {
        color: var(--main-white);
        grid-column: 8/12;
    }
    .Authentification > .auth-ctn > .logo-ctn > img {
        grid-column: 2/6;
        width: 100%;
        filter: var(--main-white-filter);
    }
}
@media screen and (min-width: 1200px) {
    .Authentification > .auth-ctn {
        margin:10% 0;
    }
    .Authentification > .auth-ctn > form {
        grid-column: 8/11;
    }
}
@media screen and (min-width: 1920px) {
    .Authentification > .auth-ctn {
        margin:5% 0;
    }
}