.Calendar {
    display: none;
}
.Calendar > .calendar-ctn.selected {
    z-index:90;
}
.Calendar > .calendar-ctn {
    padding: 10px 0;
    background: var(--main-grey-three);
    width: 100%;
    border-radius: 15px;
    margin: 1vh 0;
    display: grid;
    row-gap: 15px;
    grid-template-columns: repeat(1, 100%);
}
.Calendar > .calendar-ctn > .head-ctn {
    grid-row: 1/2;
}
.Calendar > .calendar-ctn > .dates-ctn,
.Calendar > .calendar-ctn > .head-ctn {
    grid-column: 1/8;
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    justify-items: center;
    row-gap: 15px;
}
.Calendar > .calendar-ctn > .dates-ctn > span,
.Calendar > .calendar-ctn > .dates-ctn > .date-ctn > span,
.Calendar > .calendar-ctn > .head-ctn > span {
    color: var(--main-grey-two); 
    cursor:default;
    user-select: none;
}
.Calendar > .calendar-ctn > .dates-ctn > .date-ctn > span.hide,
.Calendar > .calendar-ctn > .dates-ctn > span.hide {
    opacity: 0.5;
}
.Calendar > .calendar-ctn > .dates-ctn > .full {
    grid-column: 1/8;
    text-align: left;
    width: 80%;
}
.Calendar > .calendar-ctn .one {grid-column: 1;}
.Calendar > .calendar-ctn .two {grid-column: 2;}
.Calendar > .calendar-ctn .three {grid-column: 3;}
.Calendar > .calendar-ctn .four {grid-column: 4;}
.Calendar > .calendar-ctn .five {grid-column: 5;}
.Calendar > .calendar-ctn .six {grid-column: 6;}
.Calendar > .calendar-ctn .seven {grid-column: 7;}

.Calendar > .calendar-ctn > .divider {
    height: 1px;
    width: 80%;
    margin: auto;
    background-color: var(--main-grey-two);
}

.Calendar > .next-ctn {
    grid-row: 3/4;
    grid-column: 2 / 12;
}
.Calendar > .calendar-ctn > .dates-ctn > .date-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
} 
.Calendar > .calendar-ctn > .dates-ctn > .date-ctn > span.datas {
    background-color: var(--main-grey);
    color: var(--main-white);
    border-radius: 100%;
    width:25px;
    height:25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 900px) {
    .Calendar {
        width: 100%;
        display: grid;
        padding-top: 20px;
        grid-template-columns: repeat(12, calc(100% / 12));
    }    
    .Calendar > .next-ctn > .title-ctn,
    .Calendar > .title-ctn {
        grid-row: 1/2;
        grid-column: 2/12;
        display: grid;
        align-content: center;
        align-items: center;
        user-select: none;
    }
    .Calendar > .next-ctn > .title-ctn > h1,
    .Calendar > .title-ctn > h1 {
        color:var(--main-violet-one);
        font-size: 24px;
        grid-row: 1;
        grid-column: 1/9;
    }
    .Calendar > .title-ctn > .Button {
        grid-row: 1 / 2;
        grid-column: 8 / 9;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }
    .Calendar > .calendar-ctn {
        grid-row: 2/3;
        grid-column: 2/12;
    }
}
@media screen and (min-width:1200px) {
    .Calendar {
        width: 100%;
        display: grid;
        padding-top: 20px;
        grid-template-columns: repeat(12, calc(100% / 12));
    } 
    .Calendar > .calendar-ctn {
        grid-row: 2/3;
        grid-column: 3/11;
    }
    .Calendar > .next-ctn > .Session,
    .Calendar > .next-ctn > .Application {
        grid-column: 3 / 11;
    }
    .Calendar > .next-ctn {
        grid-column: 1/13;
        display: grid;
        grid-template-columns: repeat(12, calc(100% / 12));
    }
}
