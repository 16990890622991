.Input {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}
.Input > input {
    width: calc(100% - 10% - 4px);
    padding: 5%;
    border: 2px solid var(--main-green-two);
    border-radius: 10px;
    margin-bottom: 2vh;
}
.Input > label {
    margin-bottom: 1vh;
}
.Input > input:focus-visible {
    border: 2px solid var(--main-green-one);
    outline: none;
}