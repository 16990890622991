body {
  margin: 0;
  padding: 0;
}
/* MontSerrat Alternates Black */
@font-face {
  font-family: "montserrat-alternates-black";
  src: url('../public/fonts/Montserrat-alternates/MontserratAlternates-Black.ttf') format('truetype');
}

.MontSerrat-BlackAlternates, .caelus-app-name {
  font-family: "montserrat-alternates-black";
}
/* MontSerrat Black */
@font-face {
  font-family: "montserrat-black";
  src: url('../public/fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
}

.MontSerrat-Black, h1 {
  font-family: "montserrat-black";
}
/* MontSerrat ExtraBold */
@font-face {
  font-family: "montserrat-extrabold";
  src: url('../public/fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
}

.MontSerrat-ExtraBold, h2 {
  font-family: "montserrat-extrabold";
}
/* MontSerrat Regular */
@font-face {
  font-family: "montserrat-regular";
  src: url('../public/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

.MontSerrat-Regular, h3 {
  font-family: "montserrat-regular";
}
/* MontSerrat Light */
@font-face {
  font-family: "montserrat-light";
  src: url('../public/fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
}

.MontSerrat-Light, p, li, ul, a, label, input, span {
  font-family: "montserrat-light";
}
/* MontSerrat Light Italic*/
@font-face {
  font-family: "montserrat-light-italic";
  src: url('../public/fonts/Montserrat/static/Montserrat-LightItalic.ttf') format('truetype');
}

.MontSerrat-LightItalic {
  font-family: "montserrat-light-italic";
}
/* MontSerrat Thin */
@font-face {
  font-family: "montserrat-thin";
  src: url('../public/fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
}

.MontSerrat-Thin {
  font-family: "montserrat-thin";
}
/* MontSerrat Thin Italic*/
@font-face {
  font-family: "montserrat-thin-italic";
  src: url('../public/fonts/Montserrat/static/Montserrat-ThinItalic.ttf') format('truetype');
}

.MontSerrat-ThinItalic {
  font-family: "montserrat-thin-italic";
}

a {
  all: unset;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-green-three);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-green-two);
}

/* Colors definition */
._main_white {
  background-color: var(--main-white);
  color: var(--main-green-four);
} 
._main_full_white {
  background-color: white;
  color: var(--main-violet-one);
}
._main_green_two {
  background-color: var(--main-green-two);
  color: var(--main-white);
}
._main_green_three {
  background-color: var(--main-green-three);
  color: var(--main-white);
}
._main_full_grey {
  background-color: var(--main-grey-two);
  color: white;
}
._main_grey {
  background-color: var(--main-grey);
  color: var(--main-white);
}
._main_grey_to_white {
  color: var(--main-grey);
  background-color: var(--main-white);
}
._main_red {
  background-color: var(--main-red);
  color: var(--main-white);
}
._main_red_confirm {
  color: var(--main-red);
  background-color: var(--main-grey-three);
}
._text_green_two {
  color: var(--main-green-two);
}
._text_white {
  color: var(--main-white);
}
