.Dashboard {
    padding-top: 75px;
    min-height: calc(100vh - 75px);
}
.Dashboard > .global-ctn {
    width: calc(100%);
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, calc((100%) / 2));
}
.Dashboard > .global-ctn > .left {
    grid-column: 1/3;
}
@media screen and (min-width: 600px) and (max-width: 749px) {
    .Dashboard {
        padding-top: 60px;
        min-height: calc(100vh - 60px);
    }
}
@media screen and (min-width: 750px) {
    .Dashboard {
        padding-top: 50px;
        min-height: calc(100vh - 50px);
    }
}
@media screen and (min-width: 600px) and (max-width: 899px) {
    .Dashboard > .global-ctn {
        width: calc(100% - 60px);
        margin-left: 60px;
        display: grid;
        grid-template-columns: repeat(2, calc(100% / 2));
    }
}

@media screen and (min-width: 900px) {
    .Dashboard > .global-ctn {
        width: calc(100% - 60px);
        margin-left: 60px;
        display: grid;
        grid-template-columns: repeat(2, calc(100% / 2));
    }
    .Dashboard > .global-ctn > .Calendar {
        grid-column: 2/3;
    }
    .Dashboard > .global-ctn > .left {
        grid-column: 1/2;
    }
}