.PlannedApplications {
    display: grid;
    padding-top: 20px;
    grid-template-columns: repeat(12, calc(100% / 12));
}

.PlannedApplications > .title-ctn {
    grid-row: 1/2;
    grid-column: 2/12;
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.PlannedApplications > .title-ctn > h1 {
    color:var(--main-violet-one);
    font-size: 24px;
    grid-row: 1;
    grid-column: 1/13;
}
.PlannedApplications > .apps-ctn {
    grid-column: 1 / 13;
    grid-row:2/3;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.PlannedApplications > .apps-ctn > .Application {
    grid-column: 2/12;
}
.PlannedApplications > .Button {
    grid-column: 2/12;
    grid-row:3/4;
}
.PlannedApplications > .form-ctn {
    grid-row: 2 / 3;
    grid-column: 2 / 12;
}

@media screen and (min-width:1200px) {
    .PlannedApplications > .apps-ctn {
        grid-column: 2 / 12;
    }
}
