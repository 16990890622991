.Error {
    position: absolute;
    width: 100vw;
    height:100vh;
    background: var(--main-green-three);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Error > .ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Error > .ctn > h1 {
    color: var(--main-white);
    text-align: center;
}

