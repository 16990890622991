:root {
  --main-green-one: #005930;
  --main-green-two: #00864B;
  --main-green-two-filter: invert(26%) sepia(97%) saturate(4396%) hue-rotate(151deg) brightness(93%) contrast(101%);
  --main-green-three: #45C481;
  --main-green-four: #004235;
  --main-grey: #92B1A1;
  --main-grey-two: #707070;
  --main-grey-three: #E1DBDB;
  --main-red: #D98686;
  --main-alert-red: #910000;
  --main-violet-one: #241630;
  --main-black-transparent: #000000eb;
  --main-white: #D7F4E5;
  --main-white-transparent: #D7F4E525;  
  --main-white-filter: invert(97%) sepia(7%) saturate(613%) hue-rotate(81deg) brightness(98%) contrast(96%);
}
html, body, #root, .App {
  width: 100%;
  height: 100%;
}