/* Icon position */
.Button.left {
    flex-direction: row;
}
.Button.right {
    flex-direction: row-reverse;
}
/* Button with classic style type */
.Button.classic {
    display: flex;
    width: calc(100% - 15%);
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    border: 2px solid var(--main-green-two);
    border-radius: 10px;
    margin-bottom: 2vh;
    margin-top: 2vh;
    font-size: 1rem;
    justify-content: space-evenly;
    user-select: none;
}
.Button.classic > img {
    width: 5vw;
    filter: var(--main-white-filter);
}

/* Button with chip style type */

.Button.chip {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 30px;
    padding: 2vw 2vw;
    margin: 0 1vw;
    font-size: 0.8rem;
}
.Button.chip > span {
    font-size: 0.9rem;
    user-select: none;
}
.Button.chip > span, .Button.chip > img {
    margin:0 0 0 5px;
}

.Button  {
    cursor: pointer;
    text-align: center;
    user-select: none;
}

.ButtonTimer {
    background-color: var(--main-white);
    width: calc(100% - 40%);
    border-radius: 15px;
    height: 4px;
    margin-top: -7px;
    margin-left: auto;
    margin-right: auto;
}
.ButtonTimer > .salt {
    background-color: var(--main-grey);
    width: 0;
    border-radius: 15px;
    height: 4px;
}
@media screen and (min-width: 800px) {
    .Button.chip {
        padding: 1vw 1vw;
    }
}