.Session {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(12, calc(100% / 12));
    margin: 1vh 0;
}
.Session > .container {
    grid-column: 1/13;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    background-color: var(--main-green-three);
    border-radius: 30px;
    grid-template-rows: repeat(5, auto);
}
.Session.launching > .container {
    background-color: var(--main-grey);
}

.Session.standby > .container {
    background-color: var(--main-red);
}
.Session > .container > .firstRow {
    display: grid;
    grid-row: 1 / 2;
    grid-column: 2 / 12;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.Session > .container > .firstRow > h1 {
    color: var(--main-white);
    font-size: 1.2rem;
    grid-column: 1 / 9;
    user-select: none;
}
/* Status */
.Session > .container > .firstRow >.status {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;    
    grid-column: 9 / 13;
    justify-content: flex-end;
}
.Session > .container > .firstRow  > .status > p {
    color: var(--main-white);
    margin-right: 1.5vw;
    user-select: none;
    font-size: 0.8rem;
}
.Session > .container > .firstRow > .status > span.material-symbols-outlined {
    color: var(--main-white);
    user-select: none;
    font-size: 0.8rem;
}

/* Actions subcontainer */
.Session.creation-form > .container > .informations-ctn {
    background-color: var(--main-green-three);
    margin-top:0;
}
.Session.creation-form  > .container > .informations-ctn > ul > li > span,
.Session.creation-form  > .container > .informations-ctn > ul > li > p {
    color: var(--main-white);
}
.Session > .container > .informations-ctn {
    background-color: var(--main-white);
    width: 87.5%;
    border-radius: 15px;
    padding: 0 5%;
    grid-row: 4 / 5;
    grid-column: 1 / 13;
    margin: 1.5% 1.5%;
}
.Session > .container > .informations-ctn > ul {
    list-style: none;
    padding:0%;
}
.Session > .container > .informations-ctn > ul > li {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-user-select: none;
    user-select: none;
}
.Session > .container > .informations-ctn > ul > li > span {
    margin-right: 3%;
    color: var(--main-grey-two);
} 
.Session > .container > .informations-ctn > ul > li > p {
    margin:0;
    color: var(--main-grey-two)
} 

/* On selection */
.Session.selected {
    z-index: 90;
    position: relative;
}
.Session.creation-form > .container,
.Session.selected > .container {
    border-radius: 15px;
}

.Session.planned > .container{
    background-color: var(--main-grey);
}
.UpSubContainer{
    display: none;
    background: var(--main-red);
}
.UpSubContainer.selected {
    grid-row: 1 / 2;
    display: block;
    grid-column: 1 / 13;
    border-radius: 15px;
    height: 100px;
    margin-bottom: -60px;
}
.Session.launching >.UpSubContainer {
    background-color: var(--main-white);
}
.Session.standby > .UpSubContainer {
    background-color: var(--main-green-three);
}

.Session.creation-form > .container > .SubmitInput {
    grid-row: 5 / 6;
    display: block;
    grid-column: 3 / 11;

}
.Session.creation-form > .container {
    margin-bottom: 5vh;
}

.Session > .container > .Button {
    grid-row: 5 / 6;
    grid-column: 5 / 12;
    border-color: var(--main-red);
    width: 100%;
}