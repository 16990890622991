.Sidebar {
    padding-top: 75px;
    height: calc(100vh - 75px);
    width: 70vw;
    background-color: var(--main-green-two);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    display: grid;
    grid-template-rows: repeat(12, calc((100vh - 75px) / 12));
    grid-template-columns: repeat(12, calc(70vw / 12));
}
.Sidebar > .link {
    width: 100%;
    grid-column: 1 / 12;
    cursor: pointer;
}
.Sidebar > .one {grid-row: 1;}
.Sidebar > .two {grid-row: 2;}
.Sidebar > .three {grid-row: 3;}
.Sidebar > .four {grid-row: 4;}
.Sidebar > .five {grid-row: 5;}
.Sidebar > .six {grid-row: 6;}
.Sidebar > .seven {grid-row: 7;}
.Sidebar > .eight {grid-row: 8;}
.Sidebar > .nine {grid-row: 9;}
.Sidebar > .ten {grid-row: 10;}
.Sidebar > .eleven {grid-row: 11;}
.Sidebar > .twelve {grid-row: 12;}

.Sidebar > .link {
    color: var(--main-white);
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    align-items: center;
    grid-column: 1/13;
}
.Sidebar > .link > .img-ctn > img {
    filter: var(--main-white-filter);
    height: calc((100vh - 8vh) / 24);
}
.Sidebar > .link > span {
    font-size: calc((100vh - 8vh) / 24);
}
.Sidebar > .link > .img-ctn,
.Sidebar > .link > span {
    grid-column: 2/4;
    margin: auto;
    user-select: none;
}
.Sidebar > .link >  h3 {
    grid-column: 5/13;
    margin:0;
    user-select: none;
}

.Sidebar > .link.caelus > h1 {
    grid-column: 6/11;
    font-size: 0.7rem;
}
.Sidebar > .link.caelus > .img-ctn {
    grid-column: 3/5;
    height: calc((100vh - 30vh) / 24);
    
}

.Sidebar > .link.selected > .img-ctn > img {
    filter: var(--main-green-two-filter);
}
.Sidebar > .link.selected {
    background: var(--main-white);
    color: var(--main-green-two);
    box-shadow: -3px 1px 4px -2px var(--main-violet-one);

}
@media screen and (max-width: 599px) {
    .Sidebar.not {
        display: none;
    }
}

@media screen and (min-width: 600px) {
    .Sidebar {
        padding-top: 60px;
        height: calc(100vh - 60px);
        width: 60px;
        grid-template-rows: repeat(12, calc((100vh - 60px) / 12));
        grid-template-columns: repeat(1, calc(60px / 1));
    }
    .Sidebar > .link {
        grid-template-columns: repeat(1,60px);
    }
    .Sidebar > .link >  h3 {display: none;}
    .Sidebar:hover > .link >  h3 {
        display: block;
        grid-column: 2/6;
    }
    .Sidebar > .link > .img-ctn,
    .Sidebar > .link > span {
        grid-column: 1/2;
        margin: auto;
    }
    .Sidebar:hover > .link > .img-ctn ,
    .Sidebar:hover > .link > span {
        grid-column: 1/2;
        margin: auto;
    }
    .Sidebar:hover {
        width: 300px;
        grid-template-rows: repeat(12, calc((100vh - 60px) / 12));
        grid-template-columns: repeat(5, calc(300px / 5));
    }
}