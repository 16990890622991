.Playground > .iframe-ctn,
.Playground > .fullWindow {
    width: 100%;
    height: 40px;
    background: var(--main-green-two);
    color: var(--main-white);
    border-radius: 5px 5px 0 0;
}
.Playground > .modal-ctn {
    z-index: 90;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
}
.Playground > .modal-ctn > .selection-ctn {
    width: 60%;
    background: var(--main-black-transparent);
    color: var(--main-white);
    box-shadow: 0px 0px 20px -12px var(--main-white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    user-select: none;
}
.Playground > .modal-ctn > .selection-ctn > h3{
    cursor: pointer;
}
.Playground  >  .iframe-ctn.full-window {
    width: 100%;
    height: 100%;
    border: 0;
    background: var(--main-green-two);
}

.Playground  >  .iframe-ctn.horizontal-split {
    width: 100%;
    height: 50%;
    border: 0;
    background: var(--main-green-two);
}
.Playground  > .iframe-ctn.vertical-split {
    width: calc(50% - 1px);
    height: 100%;
    border: 0;
    background: var(--main-green-two);
}
.Playground {
    width: 100%;
    height:100vh;
    background-color: var(--main-green-two);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Playground > .iframe-ctn.not-displayed  {
    width:1px;
    height:1px;
    margin-left: -1px;
    position: absolute;
    opacity: 0;
}
.Playground > .iframe-ctn > .title-ctn,
.Playground > .fullWindow > .title-ctn {
    display: grid;
    grid-template-columns: repeat(24, calc(100% / 24));
    padding: 10px 0;
    /* justify-items: start; */
    align-items: start;
    user-select: none;
}
.Playground > .iframe-ctn > .title-ctn > .home-ctn,
.Playground > .fullWindow > .title-ctn > .home-ctn {
    grid-column: 2 / 5;
}
.Playground > .iframe-ctn > .title-ctn > .home-ctn > a,
.Playground > .fullWindow > .title-ctn > .home-ctn > a {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.Playground > .iframe-ctn > .title-ctn > .home-ctn > a > h2,
.Playground > .fullWindow > .title-ctn > .home-ctn > a > h2 {
    font-size: 1rem;
    margin: 0;
    cursor: pointer;
} 
.Playground > .iframe-ctn > .title-ctn > .home-ctn > a > img,
.Playground > .fullWindow > .title-ctn > .home-ctn > a > img {
    max-height: calc(40px - 20px);
    width: 100%;
    display: none;
    cursor: pointer;
    filter: var(--main-white-filter);
} 
.Playground > .iframe-ctn > .title-ctn > .window-ctn,
.Playground > .fullWindow > .title-ctn > .window-ctn {
    grid-column: 7 / 19;
    display: grid;
    grid-template-columns: repeat(12, calc(100%/ 12));
    align-items: center;
    width: 100%;
}
.Playground > .iframe-ctn > .title-ctn > .window-ctn .not,
.Playground > .fullWindow > .title-ctn > .window-ctn .not {
    display: none !important;
}
.Playground > .iframe-ctn > .title-ctn > .window-ctn > .back,
.Playground > .fullWindow > .title-ctn > .window-ctn > .back {
    grid-column: 1/2;
    font-size: 1rem;
    cursor: pointer;
    display: none;
}
.Playground > .iframe-ctn > .title-ctn > .window-ctn > .title,
.Playground > .fullWindow > .title-ctn > .window-ctn > .title {
    grid-column: 2/12;
    text-align: center;
    font-size: 0.9rem;
    cursor: pointer;
}
.Playground > .iframe-ctn > .title-ctn > .window-ctn > .forward,
.Playground > .fullWindow > .title-ctn > .window-ctn > .forward {
    grid-column: 12/13;
    font-size: 1rem;
    cursor: pointer;
    display: none;
}
.Playground > .iframe-ctn > .title-ctn > .settings,
.Playground > .fullWindow > .title-ctn > .settings {
    grid-column: 23/24;
    cursor: pointer;
    font-size: 1rem;
}
.Playground > .fullWindow > .title-ctn > .split-bottom, 
.Playground > .fullWindow > .title-ctn > .split {
    font-size: 1rem;
    display:none;
}
@media screen and (min-width: 500px) {
    .Playground > .iframe-ctn > .title-ctn > .home-ctn, 
    .Playground > .fullWindow > .title-ctn > .home-ctn  {
        grid-column: 2 / 6;
    }
    .Playground > .iframe-ctn > .title-ctn > .home-ctn > a > img,
    .Playground > .fullWindow > .title-ctn > .home-ctn > a > img{
        display: block;
        width: 20px;
        margin-right: 10px;
    }
}
@media screen and (min-width: 750px) {
    .Playground > .iframe-ctn > .title-ctn > .window-ctn,
    .Playground > .fullWindow > .title-ctn > .window-ctn {
        grid-column: 8 / 18;
    }
    .Playground > .iframe-ctn > .title-ctn > .split,
    .Playground > .fullWindow > .title-ctn > .split {
        display:block;
        grid-column: 22/23;
        cursor: pointer;
    }
    .Playground > .iframe-ctn > .title-ctn > .split-bottom,
    .Playground > .fullWindow > .title-ctn > .split-bottom {
        display:block;
        grid-column: 21/22;
        cursor: pointer;
    }
    .Playground > .iframe-ctn > .title-ctn > .window-ctn > .back,
    .Playground > .fullWindow > .title-ctn > .window-ctn > .back {
        display: block;
    }
    .Playground > .iframe-ctn > .title-ctn > .window-ctn > .forward,
    .Playground > .fullWindow > .title-ctn > .window-ctn > .forward {
        display: block;
    }
}
@media screen and (min-width: 1200px) {
    .Playground > .iframe-ctn > .title-ctn > .window-ctn,
    .Playground > .fullWindow > .title-ctn > .window-ctn {
        grid-column: 10 / 16;
    }
    .Playground > .iframe-ctn > .title-ctn > .split,
    .Playground > .fullWindow > .title-ctn > .split {
        display:block;
        grid-column: 23/24;
        cursor: pointer;
    }
    .Playground > .iframe-ctn > .title-ctn > .split-bottom,
    .Playground > .fullWindow > .title-ctn > .split-bottom {
        display:block;
        grid-column: 22/23;
        cursor: pointer;
    }
    .Playground > .iframe-ctn > .title-ctn > .settings,
    .Playground > .fullWindow > .title-ctn > .settings {
        grid-column: 24/25;
    }
}