.SearchBox {
    width: 100%;
}
.SearchBox > .Input > input {
    font-size: 0.7rem;
    text-align: center;
    padding: 2% 5%;
    border-radius: 20px;
    border: 0px;
    background-color: var(--main-grey-three) ;
    user-select: none;
}