.createSession {
    width: 100%;
    padding-top: 5%;
}
.createSession > form {
    display: grid;
    justify-items: center;
    row-gap: 5%;
    grid-template-columns: repeat(12, calc(100% / 12));
    grid-template-rows: repeat(7, auto);
}
.createSession > form > .alert {
    grid-column: 2 / 12;
    grid-row: 1 / 2;
    width: 100%;
    display: grid;
    color: var(--main-alert-red);
    grid-template-columns: repeat(12, calc(100% / 12));
    align-items: center;
}
.createSession > form > .alert > span.text {
    grid-column: 2/12;
}
.createSession > form > .alert > span.icon {
    grid-column: 1/2;
}
.createSession > form > .name {
    grid-row: 2/3;
    grid-column: 2/12;
}
.createSession > form > .submit {
    grid-row: 6/7;
    grid-column: 2/12;
}


.createSession > h3 {
    text-align: center;
}


/* Dates & durée */
.createSession > form > .dates-form-ctn >  p, 
.createSession > form > .dates-form-ctn > .dates-ctn > p {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.createSession > form > .dates-form-ctn >  p {
    color: var(--main-green-two);
    margin-bottom: 1vh;
}
.createSession > form > .dates-form-ctn > .dates-ctn > .Button.selected {
    max-width: 55%;
    color:white;
    background-color: var(--main-green-two);
}
.createSession > form > .dates-form-ctn > .dates-ctn > .Button.not-selected {
    max-width: 25%;
    color:white;
    background-color: var(--main-grey);
    border-color: var(--main-grey);
}
.createSession > form > .dates-form-ctn > .dates-ctn > .Button {
    max-width: 40%;
    color:white;
    background-color: var(--main-green-two);
}
.createSession > form > .dates-form-ctn > .dates-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}
.createSession.step > form > .dates-form-ctn {
    grid-row: 1 / 2;
}
.createSession.step > form > .btn-ctn > .Button {
    color:white;
    background-color: var(--main-green-two);
}
.createSession.step > form > .btn-ctn {
    grid-row: 2 / 3;
    grid-column: 2 / 12;
    width: 100%;
    display: flex;
    justify-content: center;
}
.createSession > form > .dates-form-ctn {
    grid-row: 4 / 5;
    grid-column: 2 / 12;
    width: 100%;
}

/* Liste des étudiants */
.createSession.step > form > .users-form-ctn {
    grid-row: 1 / 2;
}
.createSession.step > form > .users-form-ctn > .available {
    padding-top: 2vh;
    margin-top: -5vh;
    border: 2px solid var(--main-green-two);
    border-radius: 10px;
}
.createSession.step > form > .users-form-ctn > .available > .available-student-ctn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw;
    color: var(--main-green-two);
}
.createSession > form > .users-form-ctn >  p {
    color: var(--main-green-two);
    margin-bottom: 1vh;
    padding: 0;
    margin-top: 0;
}
.createSession > form > .users-form-ctn > .Button {
    width: 100%;
    color:white;
    background-color: var(--main-green-two);
    margin-top: 0;
}
.createSession > form > .users-form-ctn {
    grid-row: 5 / 6;
    grid-column: 2 / 12;
    width: 100%;
}
.createSession.step > form > .btn-ctn > .added-student {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--main-green-two);
    border-radius: 25px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    color: var(--main-green-two);
    padding: 1% 2%;
    margin: 0.5vh 0.5vw;
}
.createSession.step > form > .btn-ctn > .added-student > span{
    color: var(--main-alert-red);
}

.createSession.step > form > .btn-ctn > .added-student > p{
    margin: 0;
}
.createSession.step > form > .btn-ctn.users {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

}

/* Resumé de session */
.createSession > form > .Session {
    grid-row: 7/8;
    grid-column: 2 / 12;
    width: 100%;
}

/* Séparation */
.createSession > form > .divider {
    grid-row: 6 / 7;
    grid-column: 2 / 12;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    align-items: center;
}
.createSession > form > .divider > hr{
    grid-column: 5 / 13;
    margin: 0;
    border-color: var(--main-green-three);
    border-style: solid;
}
.createSession > form > .divider > p{
    grid-column: 1 / 4;
    color: var(--main-green-three);
    margin: 0;
}

/* Applications */
.createSession > form > .app-form-ctn {
    grid-row: 3 / 4;
    grid-column: 2/12;
    width: 100%;
}
.createSession > form > .app-form-ctn > .Button {
    width: 100%;
    color:white;
    background-color: var(--main-green-two);
    margin-top: 0;
}
.createSession > form > .app-form-ctn > p{
    color: var(--main-green-two);
    margin-bottom: 1vh;
    margin-top: 0;
}
.createSession.step > form > .categorie-ctn {
    grid-row: 2/3;
}
.createSession.step > form > .search {
    grid-row: 1/2;
}
.createSession > form > .search {
    grid-row: 3 / 4;
    grid-column: 2/12;
}
.createSession > form > .categorie-ctn {
    grid-row: 4/5;
    grid-column: 2 / 12;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.createSession > form > .categorie-ctn > .cat-btn-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.createSession > form > .categorie-ctn > .cat-btn-ctn > .categorie {
    margin: 0.5vh 1vw;
    border-radius: 5px;
}
.createSession > form > .categorie-ctn > .cat-btn-ctn > .environnement {
    margin: 0.5vh 1vw;
}
.createSession > form > .Button.next {
    grid-row: 3 / 4;
    grid-column: 2/12;
    width: 100%;
    color:white;
    background-color: var(--main-green-two);
}

/* Back button */
.createSession > form > .Button.back {
    grid-row: 4 / 5;
    grid-column: 2/12;
    width: 100%;
}

/* Calendar */
.createSession.step > form > .Calendar > .title-ctn {
    display: none;
}
.createSession.step > form > .Calendar {
    grid-row: 3 / 4;
    grid-column: 2/12;
    width: 100%;
    display: block;
}