.CurrentSessions {
    display: grid;
    padding-top: 20px;
    grid-template-columns: repeat(12, calc(100% / 12));
}

.CurrentSessions > .title-ctn {
    grid-row: 1/2;
    grid-column: 2/12;
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.CurrentSessions > .title-ctn > h1 {
    color: var(--main-violet-one);
    font-size: 24px;
    grid-row: 1;
    user-select: none;
    grid-column: 1/8;
}
.CurrentSessions > .title-ctn > .btn-ctn {
    grid-row: 1 / 2;
    grid-column: 10 / 13;

} 
.CurrentSessions > .title-ctn > .btn-ctn > .Button {
    margin: 0;
    justify-content: flex-start;
    padding-top: 5%;
    padding-bottom: 5%;
    width: fit-content;
}
.CurrentSessions > .apps-ctn {
    grid-column: 1 / 13;
    grid-row:2/3;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.CurrentSessions > .apps-ctn > .Session {
    grid-column: 2/12;
}
.CurrentSessions > .Button {
    grid-column: 2/12;
    grid-row:3/4;
}
.CurrentSessions > .form-ctn {
    grid-row: 2 / 3;
    grid-column: 2 / 12;
}

@media screen and (min-width:1200px) {
    .CurrentSessions > .apps-ctn {
        grid-column: 2 / 12;
    }
}
