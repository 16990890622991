.SubmitInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    cursor: pointer;
}
.SubmitInput > input {
    width: 100%;
    padding: 7%;
    border: 0;
    border-radius: 10px;
    margin-bottom: 2vh;
    font-size: 1rem;
    cursor: pointer;
}
.SubmitInput.caelus > input {
    border: double 1px transparent;
    padding: 3px;
    border-radius: 25px;
    color: var(--main-green-two);
    background-image:   linear-gradient(var(--main-white), var(--main-white)),
                        linear-gradient(45deg, #F27244, #D9ADD2, #98D2CB, #827BDB, #F2B47E);
    background-origin: border-box;
    background-clip: content-box, border-box;
    height: 5vh;
}