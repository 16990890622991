.Statistics {
    display: grid;
    padding-top: 20px;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.Statistics > .title-ctn-suite {
    grid-row: 3/4;
    grid-column: 2/12;
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(12, calc(100% / 12));
    user-select: none;
}
.Statistics > .title-ctn {
    grid-row: 1/2;
    grid-column: 2/12;
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(12, calc(100% / 12));
    user-select: none;
}
.Statistics > .title-ctn-suite > h1,
.Statistics > .title-ctn > h1 {
    color:var(--main-violet-one);
    font-size: 24px;
    grid-row: 1;
    grid-column: 1/13;
}
.Statistics > .stats-ctn {
    display: grid;
    grid-column: 2 / 12;
    grid-row: 2 / 3;
    grid-template-columns: repeat(12, calc(100% / 12));
    grid-template-rows: 100px 100px 100px;
    justify-content: space-between;
    row-gap: 10px;
}
.Statistics > .stats-ctn .stat {
    background-color: var(--main-green-three);
    width: 100%;
    border-radius: 15px;
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    grid-template-rows: 50% 50%;
}
.Statistics > .stats-ctn span {
    text-align: center;
    color: var(--main-white);
    user-select: none;
}

.Statistics > .stats-ctn .first {
    grid-row: 1 / 2;
    grid-column: 1/5;
    margin-right:5px;
    width: calc(100% - 5px);
}
.Statistics > .stats-ctn .second {
    grid-row: 1 / 2;
    grid-column: 5/9;
    margin-right: 5px;
    margin-left:5px;
    width: calc(100% - 10px);
}
.Statistics > .stats-ctn .third {
    grid-row: 1 / 2;
    grid-column: 9/13; 
    margin-left:5px;
    width: calc(100% - 5px);
}
.Statistics > .stats-ctn .fourth {
    grid-row: 2/3;
    grid-column: 1/13;
    display: flex;
    grid-template-columns: repeat(12, calc(100% / 12));
    justify-content: space-between;
}
.Statistics > .stats-ctn .fifth {
    grid-row: 3/4;
    grid-column: 1/7;
    margin-right:5px;
    width: calc(100% - 5px);
}
.Statistics > .stats-ctn .sixth {
    grid-row: 3/4;
    grid-column: 7/13;
    margin-left:5px;
    width: calc(100% - 5px);
}
.Statistics > .stats-ctn .shadow {
    box-shadow: 0px 0px 6px -2px var(--main-grey-two);
}
.Statistics > .stats-ctn span.value {
    font-size: 2rem;
}
.Statistics > .stats-ctn span.libelle {
    font-size: 0.8rem;
}

@media screen and (min-width: 650px) {
    .Statistics > .stats-ctn {
        grid-column: 3 / 11;
    }
}
@media screen and (min-width: 1200px) {
    .Statistics > .stats-ctn {
        grid-template-rows: 150px 150px 150px;
    }
    .Statistics > .stats-ctn span.value {
        font-size: 3rem;
    }
    .Statistics > .stats-ctn span.libelle {
        font-size: 1rem;
    }
}