.CreateApplication {
    width: 100%;
    padding-top: 5%;
}
.CreateApplication > form {
    display: grid;
    justify-items: center;
    row-gap: 5%;
    grid-template-columns: repeat(12, calc(100% / 12));
    grid-template-rows: repeat(7, auto);
}
.CreateApplication > form > .alert {
    grid-column: 2 / 12;
    grid-row: 1 / 2;
    width: 100%;
    display: grid;
    color: var(--main-alert-red);
    grid-template-columns: repeat(12, calc(100% / 12));
    align-items: center;
}
.CreateApplication > form > .alert > span.text {
    grid-column: 2/12;
}
.CreateApplication > form > .alert > span.icon {
    grid-column: 1/2;
}
.CreateApplication > form > .name {
    grid-row: 5/6;
    grid-column: 2/12;
}
.CreateApplication > form > .SearchBox {
    grid-row: 2/3;
    grid-column: 2/12;
}
.CreateApplication > form > .submit {
    grid-row: 6/7;
    grid-column: 2/12;
}
.CreateApplication > form > .categorie-ctn {
    grid-row: 3 / 4;
    grid-column: 2 / 12;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .categorie {
    margin: 0.5vh 1vw;
    border-radius: 5px;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .environnement {
    margin: 0.5vh 1vw;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .app-btn-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    background: var(--main-grey-three);
    border-radius: 15px;
    padding: 1vh 0;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .app-btn-ctn > .environnement {
    margin: 0.5vh 1vw;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .app-btn-ctn > p {
    font-size: 0.8rem;
    width: calc(100% - 5%);
    margin-left: 5%;
    display: flex;
    align-content: center;
    user-select: none;
}
.CreateApplication > form > .categorie-ctn > .cat-btn-ctn > .app-btn-ctn > p > span {
    font-size: 1rem;
    margin-right: 2%;
    color: var(--main-grey-two);
    cursor: pointer;
    user-select: none;
}
.CreateApplication > h3 {
    text-align: center;
}