.Loader {
    background-color: var(--main-green-three);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.Loader > img {
    width:187px;
}
.Loader > h1, .Loader > .footer > .caelus-app-name, .Loader > .footer > h3 {
    color: var(--main-white);
    margin-bottom:0;
}
.Loader > .footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    bottom: 2vh;
    align-items: flex-end;
}
.Loader > .footer > img {
    filter: var(--main-white-filter);
    width: 2vh;
    margin-right: 1vw;
} 