.Application {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(12, calc(100% / 12));
    margin: 1vh 0;
}
.Application > .container {
    grid-column: 1/13;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    background-color: var(--main-green-three);
    border-radius: 15px;
    grid-template-rows: repeat(5, auto);
}
.Application.launching > .container {
    background-color: var(--main-grey);
}

.Application.standby > .container {
    background-color: var(--main-red);
}
.Application > .container > .firstRow {
    display: grid;
    grid-row: 1 / 2;
    grid-column: 2 / 12;
    justify-content: space-between;
    align-items: start;
    grid-template-columns: repeat(12, calc(100% / 12));
}
.Application > .container > .firstRow > h1 {
    color: var(--main-white);
    font-size: 1.2rem;
    grid-column: 1 / 7;
    user-select: none;
}
/* Status */
.Application > .container > .firstRow >.status {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;    
    grid-column: 10 / 13;
    justify-content: flex-end;
}
.Application > .container > .firstRow  > .status > p {
    color: var(--main-white);
    margin-right: 1.5vw;
    user-select: none;
}
.Application > .container > .firstRow > .status > span.material-symbols-outlined {
    color: var(--main-white);
    user-select: none;
}

/* List of informations */
.Application > .container > ul.informations-gbl-ctn {
    list-style: none;
    color: var(--main-white);
    grid-column: 1/13;
    grid-row: 2/3;
} 
.Application > .container > ul.informations-gbl-ctn > li{
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-start;
    user-select: none;
} 
.Application > .container > ul.informations-gbl-ctn > li > span{
    margin-right: 3%;
} 

/* Available actions */
.Application > .container > ul.actions-gbl-ctn {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: stretch;
    justify-content: flex-end;
    grid-row: 3/4;    
    grid-column: 2 / 12;
    column-gap: 3%;
}
.Application > .container > ul.actions-gbl-ctn > li {
    margin: 5px 0;
}

/* Actions subcontainer */
.Application > .container > .informations-ctn {
    background-color: var(--main-white);
    width: 90%;
    border-radius: 15px;
    padding: 5% 5%;
    grid-row: 4 / 5;
    grid-column: 1 / 13;
}
.Application > .container > .informations-ctn > ul.actions-informations-ctn {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: stretch;
    padding: 0;
}
.Application > .container > .informations-ctn > ul.actions-informations-ctn > li {
    margin: 5px 0;
}

/* On selection */
.Application.selected {
    z-index: 90;
    position: relative;
}

.Application.planned > .container{
    background-color: var(--main-grey);
}
.UpSubContainer{
    display: none;
    background: var(--main-red);
}
.UpSubContainer.selected {
    grid-row: 1 / 2;
    display: block;
    grid-column: 1 / 13;
    border-radius: 15px;
    height: 100px;
    margin-bottom: -60px;
}
.Application.launching >.UpSubContainer {
    background-color: var(--main-white);
}
.Application.standby > .UpSubContainer {
    background-color: var(--main-green-three);
}
@media screen and (min-width: 1260px) {
    .Application > .container > .firstRow > h1 {
        grid-column: 1 / 8;
    }
}