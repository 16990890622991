.Navbar {
    height: 75px;
    width: 100%;
    background-color: var(--main-green-three);
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(12, calc(100% / 12));
    z-index: 99;
    align-content: center;
    align-items: center;
    justify-items: start;
}
.Navbar  > h3 {
    color: var(--main-white);
    grid-column: 7 / 13;
    width: calc(100% - 3vw);
    text-align: right;
    user-select: none;
}
.Navbar > .home-button {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    grid-column: 1/3;
    width: calc(100% - 3vw);
    padding-left: 3vw;
}
.Navbar > .home-button > img {
    width: 65%;
    display: none;
}
.Navbar > .home-button > span {
    display: flex;
    font-size: 1.5rem;
    color: var(--main-white);
    cursor: pointer;
    user-select: none;
}
.Navbar > .home-button > .caelus-app-name {
    color: var(--main-white);
    margin-left: 2vw;
    font-size: 1.2rem;
    user-select: none;
}
.Navbar > .informations > span.onlyhour {
    display: none;
    user-select: none;
}
.Navbar > .informations > .onlyweather {
    display: none;
    user-select: none;
}
.Navbar > .informations > .alldate {
    display: none;
    user-select: none;
}
.Navbar > .informations {
    display: none;
}

@media screen and (min-width: 600px) and (max-width: 749px) {
    .Navbar {height: 60px;}
    .Navbar > .home-button > img {width: 30%;}
    .Navbar  > h3 {
        grid-column: 10 / 13;
    }
    .Navbar > .informations {
        grid-column: 5 / 9;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .Navbar > .informations > span.onlyhour {
        color: var(--main-white);
        display: block;
        text-align: center;
        width:calc(50% - 1px);
        border-right: 2px solid var(--main-white);
    }
    .Navbar > .informations > .onlyweather {
        display: flex;
        width: calc(50% - 1px);
        justify-content: center;
        align-items: center;
    }
    .Navbar > .informations > .onlyweather > span {
        color: var(--main-white);
    }
    .Navbar > .informations > .onlyweather > span.material-symbols-outlined {
        margin-right: 5%;
    }
    .Navbar > .home-button > span {
        display: none;
    }
    .Navbar > .home-button > img {
        display: block;
    }
}

@media screen and (min-width: 750px) {
    .Navbar > .home-button > span {
        display: none;
    }
    .Navbar > .home-button > img {
        display: block;
    }
    .Navbar {height: 50px;}
    .Navbar > .home-button {     
        width: calc(100% - 1vw);
        padding-left: 1vw;
    }
    .Navbar  > h3 {
        width: calc(100% - 1vw);
        grid-column: 10 / 13;
    }
    .Navbar > .home-button > .caelus-app-name {margin-left: 1vw;}
    .Navbar > .home-button > img {width: 35px;}
    .Navbar > .informations {
        grid-column: 5 / 9;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .Navbar > .informations > span.onlyhour {
        color: var(--main-white);
        display: block;
        text-align: center;
        width:calc(50% - 1px);
        border-right: 2px solid var(--main-white);
    }
    .Navbar > .informations > .onlyweather {
        display: flex;
        width: calc(50% - 1px);
        justify-content: center;
        align-items: center;
    }
    .Navbar > .informations > .onlyweather > span {
        color: var(--main-white);
    }
    .Navbar > .informations > .onlyweather > span.material-symbols-outlined {
        margin-right: 5%;
    }
}
@media screen and (min-width: 900px) {
    .Navbar > .informations {
        grid-column: 4 / 9;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .Navbar > .informations > span.onlyhour {
        display: none;
    }
    .Navbar > .informations > .alldate {
        color: var(--main-white);
        display: flex;
        text-align: center;
        width: calc((100%* 3) / 5 - 1px - 5%);
        border-right: 2px solid var(--main-white);
        justify-content: flex-end;
        padding-right: 5%;
    }
    .Navbar > .informations > .alldate > .hour {
        color: var(--main-violet-one);
    }
    .Navbar > .informations > .onlyweather {
        display: flex;
        width: calc((100%* 2) / 5 - 1px - 5%);
        justify-content: flex-start;
        align-items: center;
        padding-left: 5%;
    }
    .Navbar > .informations > .onlyweather > span {
        color: var(--main-white);
    }
    .Navbar > .informations > .onlyweather > span.material-symbols-outlined {
        margin-right: 5%;
    }
}
@media screen and (min-width: 1400px) {
    .Navbar > .informations > .alldate {
        width: calc((100%* 3) / 5 - 1px - 2.5%);
        padding-right: 2.5%;
    }
    .Navbar > .informations > .onlyweather {
        width: calc((100%* 2) / 5 - 1px - 2.5%);
        padding-left: 2.5%;
    }
}